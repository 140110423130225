<template>
    <div v-if="modelValue" class="edit_details">
        <Form @submit="handleSubmit" v-slot="{ errors }" class="">
            <div class="header">
                <h1>Profile Edit</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="edit_wpr">
                <ul class="edit_list">
                    <li>
                        <div class="edit_card">
                            <h4>Full Name</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="Enter full name" rules="required" />
                            </div>
                            <ErrorMessage name="name" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>E-mail</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.email }">
                                <Field autocomplete="off" type="text" name="email" v-model="form.email" placeholder="Enter email address" rules="email" />
                            </div>
                            <ErrorMessage  name="email" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Mobile Number</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.number }">
                                <input type="text" v-model="form.number" placeholder="Enter mobile number" />
                            </div>
                            <ErrorMessage  name="number" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Segment</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.status }">
                                <Field autocomplete="off" name="status" v-model="form.status" :class="{ 'has-error': errors.status }">
                                    <multiselect
                                        v-model="form.status"
                                        :groups="true"
                                        :searchable="true"
                                        :options="statusesGroup"
                                        valueProp="value"
                                        label="title"
                                        placeholder="Select status"
                                        :group-select="false"
                                        group-label="type"
                                        group-options="items"
                                        :group-hide-empty="true"
                                        :loading="statusTabsLoader"
                                        @select="handleSelectStatus"
                                    >
                                        <template v-slot:grouplabel="{ group }">
                                            <span class="status-tabs-header">{{ group.type }}</span>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            <span>{{ option.title }}</span>
                                        </template>
                                    </multiselect>
                                </Field>
                            </div>
                            <ErrorMessage  name="status" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Temperature</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.temperature }">
                                <Field autocomplete="off" name="temperature" v-model="form.temperature" :class="{ 'has-error': errors.temperature }">
                                    <multiselect
                                        v-model="form.temperature"
                                        label="title"
                                        value-prop="value"
                                        :options="temperatureList"
                                        placeholder="Select"
                                    ></multiselect>
                                </Field>
                            </div>
                            <ErrorMessage  name="temperature" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Last Action Taken</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.last_action }">
                                <Field autocomplete="off" name="last_action" v-model="form.last_action" :class="{ 'has-error': errors.last_action }">
                                    <multiselect
                                        v-model="form.last_action"
                                        :options="lastActions"
                                        value-prop="value"
                                        label="label"
                                        :searchable="true"
                                        placeholder="Select last action"
                                    >
                                    </multiselect>
                                </Field>
                            </div>
                            <ErrorMessage  name="last_action" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Business Name</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.business_name }">
                                <field type="text" name="business_name" v-model="form.business_name" placeholder="Enter business name" />
                            </div>
                            <ErrorMessage  name="business_name" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Business Number</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.business_number }">
                                <input type="text" v-model="form.business_number" placeholder="Enter business number" />
                            </div>
                            <ErrorMessage  name="business_number" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Address</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.address }">
                                <Field autocomplete="off" name="address" type="text" v-model="form.address" placeholder="Enter address" />
                            </div>
                            <ErrorMessage name="address" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>City</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.city }">
                                <Field autocomplete="off" name="city" v-model="form.city" type="text" placeholder="Enter city" />
                            </div>
                            <ErrorMessage  name="city" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>State</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.state }">
                                <Field autocomplete="off" name="state" type="text" v-model="form.state" placeholder="Enter state" />
                            </div>
                            <ErrorMessage  name="state" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Zip Code</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.zipcode }">
                                <Field autocomplete="off" name="zipcode" type="text" v-model="form.zipcode" placeholder="Enter zip code" />
                            </div>
                            <ErrorMessage  name="zipcode" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Country</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.country }">
                                <Field autocomplete="off" name="country" v-model="form.country" :class="{ 'has-error': errors.country }">
                                    <multiselect
                                        v-model="form.country"
                                        :options="countries"
                                        value-prop="code"
                                        label="country"
                                        :searchable="true"
                                        placeholder="Select country"
                                    ></multiselect>
                                </Field>
                            </div>
                            <ErrorMessage  name="country" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Time zone</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.timezone }">
                                <Field autocomplete="off" name="timezone" v-model="form.timezone" :class="{ 'has-error': errors.timezone }">
                                    <multiselect
                                    v-model="form.timezone"
                                    :options="timezones"
                                    valueProp="code"
                                    label="timezone"
                                    :searchable="true"
                                    placeholder="Select timezone"
                                    >
                                    </multiselect>
                                </Field>
                            </div>
                            <ErrorMessage  name="timezone" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Gender</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.gender }">
                                <Field autocomplete="off" name="gender" v-model="form.gender" :class="{ 'has-error': errors.gender }">
                                    <multiselect
                                        v-model="form.gender"
                                        :options="genderList"
                                        value-prop="value"
                                        label="title"
                                        :searchable="true"
                                        placeholder="Select gender"
                                    ></multiselect>
                                </Field>
                            </div>
                            <ErrorMessage  name="gender" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <h4>Source</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors.source }">
                                <Field autocomplete="off" name="source" v-model="form.source" :class="{ 'has-error': errors.source }">
                                    <multiselect
                                        v-model="form.source"
                                        :options="sourceList"
                                        value-prop="value"
                                        label="title"
                                        :searchable="true"
                                        placeholder="Select source"
                                    ></multiselect>
                                </Field>
                            </div>
                            <ErrorMessage  name="source" class="text-danger" />
                        </div>
                    </li>
                    <li>
                        <div class="edit_card">
                            <div class="card_body has_icon">
                                <h4>Date of Birth</h4>
                                <div class="field_wpr" :class="{ 'has-error': errors.date_of_birth }">
                                    <Field autocomplete="off" name="date_of_birth" v-model="form.birthdate">
                                        <datepicker v-model="form.birthdate" autoApply timezone="UTC" placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                    </Field>
                                </div>
                                <ErrorMessage  name="date_of_birth" class="text-danger" />
                            </div>
                        </div>
                    </li>
                    <li  v-for="(customField, c) of customFieldsAll" :key="c">
                        <div class="edit_card">
                            <h4>{{ customField.name }}</h4>
                            <div class="field_wpr" :class="{ 'has-error': errors[`custom_field[${customField.id}]`] }">
                                <Field autocomplete="off" :name="`custom_field[${customField.id}]`" type="text" v-model="form.custom_field[customField.id]" />
                            </div>
                            <ErrorMessage :name="`custom_field[${customField.id}]`" class="text-danger" />
                        </div>
                    </li>
                </ul>
            </div>
            <div class="action_wpr">
                <button :disabled="contactLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                <button :disabled="contactLoader" class="btn danger_btn" type="button" @click="handledeleteContact(contact.id)">Delete</button>
                <button :disabled="contactLoader" class="btn save_btn" v-show="modelValue"><i class="fa fa-spinner fa-spin" v-if="contactLoader"></i>{{ contactLoader ? ' Saving' : 'Save'}}</button>
            </div>
        </Form>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapMutations } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import constants from '@/utils/Constant'

    export default {
        data () {
            return {
                lastActions: constants.last_actions,
                form: {
                    name: '',
                    email: '',
                    number: '',
                    address: '',
                    gender: '',
                    business_name: '',
                    business_number: '',
                    birthdate: '',
                    last_action: '',
                    temperature: '',
                    timezone: '',
                    source: '',
                    city: '',
                    country: '',
                    state: '',
                    status: '',
                    zipcode: '',
                    custom_field: [],
                },
                tabStatus: '',
            }
        },

        props: {
            modelValue: Boolean,
            contact: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                }
            },

            'form.country' (country) {
                const vm = this;

                if (country) {
                    vm.getTimezonesByCountry({ country });
                } else {
                    vm.resetTimezoneByCountry([]);
                }
            },
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            contactLoader: state => state.contactModule.contactSaveLoader,
            timezones: state => state.commonModule.timezonesByCountry,
            countries: state => state.commonModule.countries,
            genderList: state => state.contactModule.genderList,
            statusesGroup: state => state.contactModule.statusesGroup,
            temperatureList: state => state.contactModule.temperatureList,
            sourceList: state => state.contactModule.sourceList,
            customFieldsAll: state => state.contactModule.customFieldsAll,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
        }),

        methods: {
            ...mapActions({
                getTimezonesByCountry: 'commonModule/getTimezonesByCountry',
                saveContact: 'contactModule/saveContact',
                deleteContact: 'contactModule/bulkDelete',
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    id: vm.contact.id ? vm.contact.id : '',
                    name: vm.contact.name ? vm.contact.name : '',
                    email: vm.contact.email ? vm.contact.email : '',
                    number: vm.contact.number ? vm.contact.number : '',
                    address: vm.contact.address ? vm.contact.address : '',
                    gender: vm.contact.gender ? vm.contact.gender : '',
                    business_name: vm.contact.business_name ? vm.contact.business_name : '',
                    business_number: vm.contact.business_number ? vm.contact.business_number : '',
                    birthdate: vm.contact.birthdate ? vm.contact.birthdate : '',
                    last_action: vm.contact.last_action ? vm.contact.last_action : '',
                    temperature: vm.contact.temperature ? vm.contact.temperature : '',
                    timezone: vm.contact.timezone ? vm.contact.timezone : '',
                    source: vm.contact.source ? vm.contact.source : '',
                    state: vm.contact.state ? vm.contact.state : '',
                    status: vm.contact.status ? vm.contact.status : '',
                    zipcode: vm.contact.zipcode ? vm.contact.zipcode : '',
                    country: vm.contact.country ? vm.contact.country : '',
                    city: vm.contact.city ? vm.contact.city : '',
                    custom_field: vm.contact.custom_field ? vm.contact.custom_field : [],
                };
            },

            handledeleteContact (id) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', "You will not be able to recover these contacts.", 'Delete');

                option.preConfirm = function() {
                    return vm.deleteContact({ contacts: [id] }).then((result) => {
                        if (result) {
                            if (vm.$parent.$parent && vm.$parent.$parent.$parent.closeModal) {
                                vm.$parent.$parent.$parent.closeModal();
                            } else if (vm.$parent.$parent.closeModal) {
                                vm.$parent.$parent.closeModal();
                            }

                            vm.closeModal();
                        }
                    });
                }

                Swal.fire(option);
            },

            handleSubmit (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;

                vm.saveContact(vm.form).then((result) => {
                    if (result) {
                        if (vm.$parent.$parent && vm.$parent.$parent.$parent && vm.$parent.$parent.$parent.$parent && vm.$parent.$parent.$parent.$parent.params && vm.$parent.$parent.$parent.$parent.params.type) {
                            vm.$parent.$parent.$parent.$parent.params.type = vm.tabStatus;
                        } else if (vm.$parent.$parent && vm.$parent.$parent.$parent && vm.$parent.$parent.$parent.params && vm.$parent.$parent.$parent.params.type) {
                            vm.$parent.$parent.$parent.params.type = vm.tabStatus;
                        }

                        vm.closeModal();
                    }
                })
            },

            handleSelectStatus (status, tab) {
                const vm = this;

                vm.tabStatus = tab.value;
            }
        }
    }
</script>

<style scoped>
    :deep(.multiselect-placeholder) {
        font-size: 13px;
        color: #757575;
    }
    .global_setting .tabs_content form>.action_wpr{
        margin: 0 -30px;
        padding: 20px 30px;
    }
    .edit_details .action_wpr{
        padding: 20px 30px;
        margin: 0;
    }
</style>
